<template >
    <div id="event" >
      <div class="container" style="margin-top:10rem;">   
        <span class="trial-category" style="text-align: center; margin-bottom:0;">Scopri le Attività</span>
        <h2 class="trial-title" style="text-align: center; margin-bottom:7rem;">Appuntamenti</h2>     
        <!-- <h2 class="section-title" style="padding-top:10rem; padding-bottom:5rem; text-align: center;">Attività</h2> -->
      </div>
    <div class="chessboard">
      <div v-for="(item, index) in items" :key="index" class="chess-item row" :class="{ 'reverse': index % 2 !== 0 }">
        <div class="image animated-image-container col-12 col-md-6 p-0 m-0">
          <img :src="item.image" :alt="item.title" class="animated-image" />
        </div>
        <div class="description col-12 col-md-6 mt-5 mt-md-0" :class="{ 'reverse': index % 2 !== 0 }">
          <span class="trial-category" style="margin-bottom:0;">{{ item.date }}</span>
          <h3>{{ item.title }}</h3>
          <p>{{ item.description }}</p>
        </div>
      </div>
    </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        items: [
         {
            title: 'Test Drive',
            description: 'Novità di quest’anno è il test drive, con il quale, nel pomeriggio del 5/10, i visitatori potranno provare i mezzi messi a disposizione dalle concessionarie testando le novità in prima persona nelle apposite aree organizzate. Saremo accompagnati in questo percorso dall’influencer del trasporto Laura Broglio, che ci rilancerà a livello nazionale sui canali social.',
            image: 'images/event/TestDrive.jpg',
            date: ' 05/10 16:00-19:00 '
          },
          {
            title: 'Talk',
            description: 'L’area Talk sarà dedicata alle tavole rotonde e alle interviste con gli stakeholders, gli organizzatori e le figure di spicco delle principali aziende del settore. Gli incontri saranno un’occasione di confronto e approfondimento su tematiche cruciali per il futuro del trasporto e della logistica. Con relatori di alto livello e argomenti di grande attualità, le sessioni Talk promettono di stimolare il dibattito, offrendo spunti preziosi e nuove visioni.',
            image: 'images/event/Talk.jpg',
            date: ' 05/10 17:30 '
          },
          {
            title: 'Best Truck',
            description: 'La competizione Best Truck è una celebrazione della creatività e dell\'originalità dei trucker. I partecipanti si sfideranno per il titolo di mezzo più bello della giornata, esibendo colori vivaci, serigrafie e personalizzazioni che li rendono unici. Saranno premiati i camion che sapranno sorprendere e affascinare i giudici e il pubblico con il loro design straordinario e la cura dei dettagli. Un\'occasione imperdibile per ammirare veri e propri giganti su ruote.',
            image: 'images/event/BestTruck.jpg',
            date: ' 06/10 all day '

          },
          {
            title: 'Truck Pulling',
            description: 'Dalla tradizione nordamericana, arriva al Salento Truck la spettacolare prova di forza del Truck Pulling. In questa competizione, i camion dovranno trainare un semirimorchio zavorrato a 450 quintali su un percorso in pendenza. Vince il trattore stradale che riuscirà a raggiungere il traguardo nel minor tempo possibile. Potenza, resistenza e esperienza saranno messe alla prova in una gara che promette di tenere tutti con il fiato sospeso fino all\'ultimo secondo.',
            image: 'images/event/TruckPulling.jpg',
            date: ' 06/10 10:30 '
          },
          {
            title: 'Gran Prix',
            description: 'La Grand Prix è la sfida di abilità per eccellenza, che anno dopo anno continua a coinvolgere sempre più sfidanti e visitatori. I driver, alla guida di camion con rimorchio, si metteranno alla prova su un percorso a slalom. Precisione, controllo e velocità saranno fondamentali per superare gli ostacoli e completare il percorso nel minor tempo possibile aggiudicandosi un posto sul podio dei vincitori. Una gara avvincente che metterà in mostra il talento e la destrezza dei migliori autisti del settore.',
            image: 'images/event/GranPrix.jpg',
            date: ' 06/10 15:30 '
          },
          // Aggiungi altri elementi se necessario
        ]
      };
    }
  };
  </script>
  
  <style scoped>

  
  .chessboard {
    display: flex;
    flex-direction: column;
  }
  
  .chess-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0px;

  }
  
  /* Applica il layout invertito per le righe dispari */
  .chess-item.reverse {
    flex-direction: row-reverse;
    /* background-color: #231f20; */

  }
  .description.reverse {
    text-align: right;
    /* background-color: #231f20; */

  }
  .image{
  }
  .image img {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }
  
  .description {
    flex: 1;
    padding: 0 70px 20px;
  }
  
  h3 {
    margin-bottom: 10px;
    font-family: "Poppins";
    font-size: 3rem;
  }
  
  p {
    margin: 0;
    text-align: justify;
  }

  .animated-image-container {
  overflow: hidden; /* Assicura che l'immagine non esca dal contenitore durante l'animazione */
}

.animated-image {
  width: 100%; /* Adatta la dimensione dell'immagine al contenitore */
  height: auto; /* Mantiene le proporzioni dell'immagine */
  transform: translateX(-100%); /* Posiziona l'immagine fuori dal viewport a sinistra */
  animation: slideIn 1s forwards; /* Applica l'animazione */
}

@keyframes slideIn {
  from {
    transform: translateX(-100%); /* Inizia fuori dal viewport a sinistra */
  }
  to {
    transform: translateX(0); /* Termina alla posizione normale */
  }
}
  </style>
  