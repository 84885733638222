<template>
    <!-- Begin Banner Area -->
    <div id="game" class="banner-area banner-style-8" data-bg-image="" :style="{backgroundImage: `url(${ videoBannerBg })`}">
        <div class="container">
            <div class="row">
                <div class="col-lg-7 align-self-center">
                    <div class="banner-item">
                        <div class="banner-content common-style">
                            <span class="banner-category">Trasportatore per un giorno</span>
                            <h2 class="banner-title">Entra nel mondo del trasporto in prima persona</h2>
                            <p class="banner-desc">Prova a comporre il tuo pallet, distribuisci la merce in tutta italia, cambia una ruota per strada. <br> Attento alle tue spedizioni</p>
                            <div class="banner-btn-wrap">
                                <a href="#pGame" class="btn btn-custom-size blackberry-color extbot-primary-hover-4">Gareggia</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Banner Area End Here -->
</template>

<script>
export default {
  data () {
    return {
      videoBannerBg: 'images/banner/Banner.png'
    }
  }
}
</script>
