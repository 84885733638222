<template>
    <div id="app" style=" padding-top:7rem">
        <div>
            <span class="trial-category">IV Edizione 2024</span>
            <h2 class="trial-title">Gold Sponsor</h2>
            <p class="trial-desc">I nostri gold sponsor che credono in questo amato e sentito evento degli organizzatori in questa speciale edizione  </p>      
        </div>
      <div class="card-container">
        <p v-if="companies.length === 0">Nessuna azienda da mostrare.</p>
        <div v-for="(company, index) in companies" :key="index" class="card">
          <div class="card-image-container">
            <img :src="company.logo" alt="Company Logo" class="card-logo" />
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        companies: [
          {
            logo: 'images/gold/Lotras.png',
            backgroundColor: '#f5a623', // Colore di sfondo per la card
          },
          {
            logo: 'images/gold/TFP.png',
            backgroundColor: '#50e3c2',
          },
          {
            logo: 'images/gold/UTA.png',
            backgroundColor: '#4a90e2',
          }
        ]
      };
    }
  }
  </script>
  
  <style scoped>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
  }
  
  .card-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .card {
    width: 350px; /* Larghezza della card */
    height: 150px; /* Altezza della card */
    border-radius: 12px;
    overflow: hidden;
    background-color: #fff; /* Colore di sfondo di default */
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s;
  }
  
  .card:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .card-image-container {
    width: 80%;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .card-logo {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    border-radius: 12px;
  }
  
  .card:nth-child(1) {
    background-color: #e5017d; /* Colore di sfondo per la prima card */
  }
  
  .card:nth-child(2) {
    background-color: white; /* Colore di sfondo per la seconda card */
  }
  
  .card:nth-child(3) {
    background-color:#e93323; /* Colore di sfondo per la terza card */
  }
  </style>
  