<template>
    <!-- Begin Banner Area -->
    <div class="banner-area banner-style-9">
        <div class="container banner-container">
            <div class="row">
                <div class="d-xl-block d-none col-lg-6">
                    <div class=" banner-img"  data-bg-image="" :style="{backgroundImage: `url(${ introBg })`}">
                        <lightgallery
                            :settings="{
                                speed: 500,
                                plugins: plugins,
                                controls: false
                            }"
                            class="popup-btn"
                            
                        >
                            <div
                                class="popup-vimeo wave-btn style-2" 
                                data-src="https://www.youtube.com/watch?v=omc9Z_RTzdk"
                            >
                                <span></span>
                                <span></span>
                                <span></span>
                                <div class="icon" style="background-color:#D63684" >
                                    <i class="icofont-play"></i>
                                </div>
                            </div>

                        </lightgallery>
                    </div>
                    
                </div>
                <div class="d-xl-none d-block col-lg-6">
                    <div class=" banner-img"  data-bg-image="" :style="{backgroundImage: `url(${ introBgSm })`}">
                        <lightgallery
                            :settings="{
                                speed: 500,
                                plugins: plugins,
                                controls: false
                            }"
                            class="popup-btn"
                            
                        >
                            <div
                                class="popup-vimeo wave-btn style-2" 
                                data-src="https://www.youtube.com/watch?v=omc9Z_RTzdk"
                            >
                                <span></span>
                                <span></span>
                                <span></span>
                                <div class="icon" style="background-color:#D63684" >
                                    <i class="icofont-play"></i>
                                </div>
                            </div>

                        </lightgallery>
                    </div>
                    
                </div>
                <div class="col-lg-6 align-self-center">
                    <div class="banner-content common-style">
                        <span class="banner-category" style="color: $mn-color">Gold Sponsor</span>
                        <h2 class="banner-title">Lotras s.r.l.</h2>
                        <!-- <p class="banner-desc">Lotras oggi riveste una forte presenza sul territorio Salentino dove, grazie al suo servizio intermodale combinato tra Brindisi e Forli, ha rivoluzionato il modo di viaggiare delle merci.<br> Quest'anno gold sponsor del Salento Truck</p> -->
                        <p class="banner-desc">Lotras sempre una più solida presenza sul territorio Salentino, grazie al suo servizio intermodale combinato che unisce Brindisi a Forlì, rinnova per il seconda anno di seguito la fiducia nel Salento Truck, riconfermandosi <br> Gold sponsor della III edizione</p>
                        <div class="banner-btn-wrap">
                            <a target="blanck" href="https://www.lotras.it/it/" class="btn btn-custom-size ms-color extbot-primary-hover-4">Scopri</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Banner Area End Here -->
</template>

<script>
import Lightgallery from 'lightgallery/vue'
import lgZoom from 'lightgallery/plugins/zoom'
import lgVideo from 'lightgallery/plugins/video'

export default {
  name: 'App',
  components: {
    Lightgallery
  },
  data () {
    return {
      plugins: [lgZoom, lgVideo],
      introBg: 'images/banner/9-1-673x673.png',
      introBgSm: 'images/banner/lotrasLite.png'
    }
  }
}
</script>
<style lang="css">
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.0.0-beta.4/css/lightgallery.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.0.0-beta.4/css/lg-zoom.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.0.0-beta.4/css/lg-video.css');
.popup-vimeo {
  cursor: pointer;
}
</style>
