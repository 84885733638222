<template>
    <div id="contact" class="form-area form-style-2 mb-5" style="background-color: #f1a949; margin-top:7rem">
        <div class="container">
           
            <div class="form-wrap newsletter-item ">
                <div class="row">
                    <h2 class="newsletter-title" style="color:black;"> {{title}}</h2>
                    <div class="col-xl-12 col-lg-12">
                        <form id="contact-form" ref="formX" class="form " @submit.prevent="sendEmail">
                            <div class="form-field">
                                <input type="text" v-model="currentContact.name" name="name" placeholder="Nome" class="input-field">
                            </div>
                            <div class="form-field">
                                <input type="text" v-model="currentContact.email" name="email" placeholder="Email" class="input-field">
                            </div>
                            <div class="form-field">
                                <input type="text" v-model="currentContact.subject" name="subject" placeholder="Oggetto" class="input-field">
                            </div>
                            <div class="form-field mb-0">
                                <textarea name="message" v-model="currentContact.message" placeholder="Scrivi qui..." class="textarea-field"></textarea>
                            </div>
                            <div class="form-btn-wrap">
                                <button type="submit" style="background-color: black;" value="submit" class="form-btn" name="submit">
                                    Invia
                                    <i class="icofont-long-arrow-right"></i>
                                </button>
                                <p class="form-messege"></p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Swal from "sweetalert2";
import emailjs from 'emailjs-com';


export default {
  data () {
    return {
      title:"Contattaci",
      mainBg: '/images/banner/backCont.png',
      currentContact: {
        name:"",
        email:"",
        subject:"",
        message:"",
      },
      lastContact: {
        name:"",
        email:"",
        subject:"",
        message:"",
      }
    }
  },

  methods:{
    sendEmail(event) {

        this.lastContact.name=this.currentContact.name;
        this.lastContact.email=this.currentContact.email;
        this.lastContact.subject=this.currentContact.subject;
        this.lastContact.message=this.currentContact.message;
        
        this.currentContact.name='';
        this.currentContact.email='';
        this.currentContact.subject='';
        this.currentContact.message='';
        console.log(this.lastContact);
        emailjs.sendForm(
            'service_io8etc8',
            'template_anvjcx9',
            this.$refs.formX, 
            '1cNk8zhf1EBbhguHZ',
            {
                name: this.lastContact.name,
                email:  this.lastContact.email,
                subject:  this.lastContact.subject,
                message:  this.lastContact.message,
                

            }
        )
        .then(
            (result) => {
                console.log('SUCCESS', result.text);
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "Messaggio Inviato",
                    showConfirmButton: false,
                    timer: 1000,
                });
            }, 
            (error) => {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: "Errore di invio",
                    showConfirmButton: false,
                    timer: 1000,
                });
                console.log('FAILED...', error.text);
            }
        );
        }
  }
}
 </script> 