<template>
    <!-- Begin Banner Area -->
    <div class="banner-area banner-style-9">
        <div class="container banner-container">
            <div class="row">
                <div class="col-lg-6 align-self-center">
                    <div class="banner-content common-style">
                        <span class="banner-category" style="color: $mn-color">Gold Sponsor</span>
                        <h2 class="banner-title" style="color:#15386F">Lotras s.r.l.</h2>
                        <p class="banner-desc">Concessionaria MP Volvo Trucks da 40 anni leader nel settore e sostenitrice fin dalla I Edizione del Salento Truck, rinnova quest'anno la sua fiducia nel territorio e nell'evento diventando <br> Gold sponsor di questa III edizione</p>
                        <div class="banner-btn-wrap">
                            <a target="blanck" href="https://www.volvotrucks.it/it-it/news/press-releases/2018/may/pressrelease-180531.html" class="btn btn-custom-size ms-color extbot-primary-hover-4" style="background-color: #15386F;">Scopri</a>
                        </div>
                    </div>
                </div>
                <div class="d-xl-block d-none col-lg-6">
                    <div class=" banner-img"  data-bg-image="" :style="{backgroundImage: `url(${ introBg })`}">
                        <lightgallery
                            :settings="{
                                speed: 500,
                                plugins: plugins,
                                controls: false
                            }"
                            class="popup-btn"
                            
                        >
                            <div
                                class="popup-vimeo wave-btn style-2" 
                                data-src="https://www.youtube.com/watch?v=m8H_TrxtsZo"
                            >
                                <span></span>
                                <span></span>
                                <span></span>
                                <div class="icon" style="background-color:#15386F" >
                                    <i class="icofont-play"></i>
                                </div>
                            </div>

                        </lightgallery>
                    </div>
                    
                </div>
                <div class="d-xl-none d-block col-lg-6">
                    <div class=" banner-img"  data-bg-image="" :style="{backgroundImage: `url(${ introBgSm })`}">
                        <lightgallery
                            :settings="{
                                speed: 500,
                                plugins: plugins,
                                controls: false
                            }"
                            class="popup-btn"
                            
                        >
                            <div
                                class="popup-vimeo wave-btn style-2" 
                                data-src="https://www.youtube.com/watch?v=m8H_TrxtsZo"
                            >
                                <span></span>
                                <span></span>
                                <span></span>
                                <div class="icon" style="background-color:#15386F" >
                                    <i class="icofont-play"></i>
                                </div>
                            </div>

                        </lightgallery>
                    </div>
                    
                </div>
                
            </div>
        </div>
    </div>
    <!-- Banner Area End Here -->
</template>

<script>
import Lightgallery from 'lightgallery/vue'
import lgZoom from 'lightgallery/plugins/zoom'
import lgVideo from 'lightgallery/plugins/video'

export default {
  name: 'App',
  components: {
    Lightgallery
  },
  data () {
    return {
      plugins: [lgZoom, lgVideo],
      introBg: 'images/banner/mp-673x673.png',
      introBgSm: 'images/banner/mp-lite.png'
    }
  }
}
</script>
<style lang="css">
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.0.0-beta.4/css/lightgallery.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.0.0-beta.4/css/lg-zoom.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.0.0-beta.4/css/lg-video.css');
.popup-vimeo {
  cursor: pointer;
}
</style>
