<template>
    <!-- Begin Banner Area -->
    <div class="banner-area banner-style-7 bg-banner banner-info" :style="{backgroundImage: `url(${ bgImage })`}" style="margin-top:20rem; margin-bottom:10rem; padding-bottom:0;">
        <div class="container banner-container-2">
            <div>
                <div class="banner-content" style="background-color:#292929; opacity: 80%; ;padding:2rem; width: 100%;">
                    
                    <span class="banner-category" style="color:#f1a949;" >Food and Beverage</span>
                    <h2 class="banner-title" style="color:white;">Street Food Area</h2>
                    <p class="banner-desc" style="width: 100%;">L'area offre un'esperienza street food in
                                                                perfetto stile trucker, in un viaggio
                                                                culinario come solo i nostri amici su ruote
                                                                sanno fare.
                        </p>
                    <div class="banner-btn-wrap">
                            <!--<router-link to="/contact" class="btn btn-custom-size blackberry-color extbot-primary-hover-4">Scopri</router-link>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Banner Area End Here -->
</template>

<script>
export default {
  data () {
    return {
      bgImage: 'images/banner/streetFood.jpeg',
      
    }
  }
}
</script>
<style scoped>
.bg-banner {
            position: relative;
            height: 500px; /* Imposta l'altezza del div */
            background-attachment: fixed; /* Fissa l'immagine di sfondo */
            background-size: cover; /* Adatta l'immagine al contenitore */
            background-position: center; /* Centra l'immagine */
        }
.banner-info{
  display: flex;
  justify-content: center; /* Allinea orizzontalmente al centro */
  align-items: center; /* Allinea verticalmente al centro */
}
</style>