<template>
    <div class="contact-map">
        <div class="container">
            <div class="row" style="">
                <div>
                    <span class="banner-category" style="color:#f1a949; text-align: center; margin-bottom:0;" >Dove trovarci</span>
                    <h2 class="section-title" style="padding-top:0; padding-bottom:0rem; text-align: center;">Posizione</h2>
                    <p class="" style="padding-bottom:4rem;  width: 100%; color: #57617b; font-size: 25px; line-height: 45px; text-align: center;">Zona Industriale, Tronco c/d, 73042, Casarano (LE)</p>

                </div>
                <div class="col-12" style="border-radius: 100px;">
                    <iframe class="contact-map-size" src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d1615.7874692524128!2d18.183109720694205!3d40.03129189628186!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sit!4v1726476810382!5m2!1sen!2sit" allowfullscreen="" loading="lazy">
                    </iframe>
                </div>
            </div>
        </div>
    </div>
</template>