<template>

    <!-- Begin Feature Area -->
    <div id="feature" class="feature-area feature-style-4">
        <div class="container feature-container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="feature-img">
                        <div class="single-img">
                            <img src="images/feature/image/street.png" style="margin-top:300px" alt="Feature Image">
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="feature-list-content">
                        <h2 class="feature-title">Competizioni ed eventi</h2>
                        <p class="feature-desc">Scopri le attività presenti durante la giornata</p>
                        <div class="feature-wrap">

                            <div  v-for="(item, index) in items" :key="index" class="feature-item">

                                <div class="feature-icon">
                                    <img :src="`${item.imgSrc}`" :alt="item.alt">
                                </div>
                                <div class="feature-content">
                                    <h3 class="title">{{ item.title }}</h3>
                                    <p class="desc" v-html="item.description"></p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Feature Area End -->

</template>
<style>
  .key-word{
    font-weight: bold;
  }
</style>
<script>
export default {
  data () {
    return {
      items: [
        {
          imgSrc: 'images/feature/icon/start.png',
          alt: 'Feature Icon',
          title: 'Apertura',
          description: "Apertura e presentazione dell'evento"
        },
        {
          imgSrc: 'images/feature/icon/gincana.png',
          alt: 'Gran Prix',
          title: 'Gran Prix',
          description: 'I nostri driver alla guida di camion con rimorchio si metteranno alla prova nel <span class="key-word"> percorso a slalom</span>. Chi spodesterà il vecchio campione?'
        },
        {
          imgSrc: 'images/feature/icon/pulling.png',
          alt: 'Feature Icon',
          title: 'Truck Pulling',
          description: 'Dal Nord America al Sud Italia... arriva per la prima volta la <span class="key-word"> prova di tiro </span> dei Camion con un semirimorchio zavorrato a 450 q.li. Quale sarà il Trattore Stradale che raggiungerà il traguardo nel <span class="key-word"> più breve tempo possibile</span>? '
        },
        {
          imgSrc: 'images/feature/icon/best.png',
          alt: 'Feature Icon',
          title: 'BEST TRUCK',
          description: 'I nostri truck si contenderanno tra <span class="key-word"> colori e personalizzazioni</span> il titolo di mezzo più bello della giornata'
        },
        {
          imgSrc: 'images/feature/icon/interview.png',
          alt: 'Feature Icon',
          title: 'Interviste',
          description: 'Non perdere tutte le <span class="key-word"> interviste </span> dedicate agli <span class="key-word"> argomenti </span> più <span class="key-word"> rombanti </span> del momento'
        },
      ]
    }
  }

}

</script>
