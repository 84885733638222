<template>
    <!-- Begin Trial Area -->
    <div id="competition" class="trial-area trial-style-1" style="height: 100vh;">
        <div class="container" style="height:100%; display: flex; justify-content: center; align-items: center; ">
            <div class="row">
                <div class="col-lg-12">
                    <div class="trial-item">
                        <span class="trial-category">Mettiti alla prova</span>
                        <h2 class="trial-title">Iscriviti alle competizioni</h2>
                        <p class="trial-desc">Sei un autista? Vuoi dimostrare a tutti le tue abilità? <br> Inizia a scaldare il motore... aggiudicati il titolo di <span class="label label-info" style="font-weight:bold">CAMPIONE</span>
                            
                        </p>
                        <div class="banner-btn-wrap">
                            <router-link to="/iscrizioni" class="btn btn-custom-size blackberry-color extbot-primary-hover-4"><span style="font-size:1.2em"><i class="icofont-pencil-alt-1" ></i> Iscriviti</span></router-link>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Trial Area End Here -->
</template>
