<template>
    <div v-for="(price, index) in pricing" :key="index" class="col-lg-4">
        <div class="pricing-item">
            <div class="pricing-wrap">
                <div class="pricing-info">
                    <span class="price">{{price.value}}</span>
                </div>
            </div>
            <div class="pricing-list-wrap">
                <h2 class="pricing-title">{{price.title}}</h2>
                <ul class="pricing-list style-2">
                    <li v-for="(list, index) in price.lists" :key="index">
                        <router-link to="/gameSubscribe">{{list.text}}</router-link>
                    </li>
                </ul>
                <div class="pricing-btn-wrap">
                    <a target="_blanc" class="btn btn-custom-size btn-outline" :href=price.url>
                        Scopri
                        <i class="icofont-double-right"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  data () {
    return {
      pricing: [
        {
          value: '€1',
          title: 'Truck Race',
          url:"doc/Truck_Race.pdf",
          lists: [
            {
              text: ' Aggancia il rimorchio '
            },
            {
              text: ' Ritira la merce'
            },
            {
              text: ' Attento la merce è fragile'
            }
          ]
        },
        {
          value: '€1',
          title: 'Cartoon Race',
          url:"doc/Cartoon_Race.pdf",
          lists: [
            {
              text: ' Il tempo stringe '
            },
            {
              text: ' Sali a bordo del tuo truck'
            },
            {
              text: ' Il destinario ti aspetta'
            }
          ]
        },        
        {
          value: '€1',
          title: 'Palletways Game',
          url:"doc/Palletways_Game.pdf",
          lists: [
            {
              text: ' Consegna in 24h?'
            },
            {
              text: ' Diventa un concessionario'
            },
            {
              text: ' Non fare ritardo '
            }
          ]
        }
      ]
    }
  }
}
</script>
