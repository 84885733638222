<template>
  <div class="wrapper">

    <router-view></router-view>

    <!-- HeaderNavFour section -->
    <HeaderNav></HeaderNav>

    <!-- HeroSectionFour section -->
    <HeroSectionFive></HeroSectionFive>

    <!--gold Sponsor-->
    <GoldSponsor></GoldSponsor>

    <!-- Scaletta evento -->
    <EventProgram></EventProgram>

    <!-- FeatureSectionFour section -->
    <!-- <FeatureSectionFour></FeatureSectionFour> -->

    <!-- TrialOne section -->
    <TrialOne></TrialOne>
    
    <!-- PartnerSectionThree section -->
    <StandSection ></StandSection>

    <!-- Banner Gareggia ai giochin -->
    <!-- <BannerSeven></BannerSeven> -->
    
    <!-- PricingPlanFour giochi iscrizioni section -->
    <!-- <PricingPlanFour></PricingPlanFour> -->

    <GoogleMaps></GoogleMaps>

    <!-- HeroSectionFour section -->
    <StreetFood></StreetFood>
    
    <!-- HeroSectionFour section -->
    <!-- <ScreenImage></ScreenImage> -->

    <!-- VideoBannerFour section -->
    <!-- <VideoBannerFour></VideoBannerFour> -->

    <!-- VideoBannerFour section -->
    <!-- <VideoBannerFive></VideoBannerFive> -->

    <!-- PartnerSectionThree section -->
    <PartnerSectionThree styleClass="partner-style-4" swiperStyleClass="style-3"></PartnerSectionThree>
    
    <!-- NewsletterThree Section -->
    <Contact></Contact>

    <!-- FaqSection section -->
    <FaqSection></FaqSection>

   

    <!-- Footer section -->
    <FooterSectionFour></FooterSectionFour>

  </div>
</template>

<script>
import HeaderNav from '@/components/HeaderNavST.vue'
import HeroSectionFive from '@/components/HeroSectionFive.vue'
import PartnerSectionThree from '@/components/PartnerSection.vue'
import FeatureSectionFour from '@/components/FeatureSectionFour.vue'
import StandSection from '@/components/Stand.vue'
import TrialOne from '@/components/TrialOne.vue'
import BannerSeven from '@/components/BannerSeven.vue'
import PricingPlanFour from '@/components/PricingPlanFour.vue'
import StreetFood from '@/components/StreetFoodArea.vue'
import ScreenImage from '@/components/ScreenImage.vue'
import VideoBannerFour from '@/components/VideoBannerFour.vue'
import VideoBannerFive from '@/components/VideoBannerFive.vue'
import FaqSection from '@/components/FaqSection.vue'
import Contact from '@/components/ContactST.vue'
import FooterSectionFour from '@/components/FooterSectionST.vue'
import EventProgram from '@/components/EventProgram.vue'
import GoogleMaps from '@/components/GoogleMaps.vue'
import GoldSponsor from '@/components/GoldSponsor.vue'


export default {
components: {
  HeaderNav,
  HeroSectionFive,
  PartnerSectionThree,
  StandSection,
  FeatureSectionFour,
  TrialOne,
  BannerSeven,
  ScreenImage,
  PricingPlanFour,
  StreetFood,
  VideoBannerFour,
  VideoBannerFive,
  FaqSection,
  Contact,
  FooterSectionFour,
  EventProgram,
  GoogleMaps,
  GoldSponsor
}
}
</script>
