<template>
    <!-- FAQ Area End Here -->
    <div id="faqSection" class="faq-area faq-style-common faq-style-1">
        <div class="container">
            <div class="section-area">
                <h2 class="section-title">Question & Answer</h2>
                <p class="section-desc">Rispondiamo velocemente ad alcune delle vostre domande</p>
            </div>

            <div class="row">
                <div v-for="(order, index) in paginatedOrders" :key="index" class="col-lg-6">
                    <div class="faq-item-wrap row">
                        <div v-for="(item, index) in order.items" :key="index" class="col-12">
                            <div class="faq-item">
                                <h3 class="faq-title" v-html=item.title></h3>
                                <p class="faq-desc" v-html=item.desc ></p>
                                <p class="faq-desc">{{item.desc2}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-if="currentPage * maxPerPage < orders.length">
                <div class="col-12">
                    <div class="faq-btn-wrap">
                        <a class="btn btn-custom-size blackberry-color extbot-primary-hover-4" @click="loadMore">Vedi Altre</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getRandomValues } from 'crypto'

export default {
  data () {
    return {
      orders: [
        {
          items: [
            {
              title: "Cos'è il Salento truck?",
              desc: "Il salento Truck è un evento creato dall'associazione culturale iFuture con lo scopo di radunare gli appassionati del mondo del truck."
            },
            {
              title: "Bisogna pagare per accedere all'evento?",
              desc: "L'evento è totalmente gratuito ai visitatori."
            },
            {
              title: "Come si svolgerà l'evento?",
              desc: "All'interno dell'area troverai varie competizioni tra i partecipanti, giochi per bambini e non, un'area street food e Radio Diffusione Stereo a tenerci compagnia durante la giornata.",
            }
          ]
        },
        {
          items: [
            {
              title: "Per partecipare alle competizioni è necessario iscriversi?",
              desc: "Per partecipare al Grand Prix, Truck Pulling e Best Truck è necessario compilare la preiscrizione presente sul nostro sito nella sezione 'Iscrizioni'."
            },
            {
              title: "&Egrave; prevista una vincita?",
              desc: "Ai partecipanti che si aggiudicheranno la vittoria verrà fatta una premiazione.",
            },
            {
              title: "Cosa prevede l'area per bambini?",
              desc: "Per i bambini ci saranno due competizioni: la CARTON RACE, una gara a tempo con merce fragile ed il PALLETWAYS GAME dove bisognerà consegnare nel minor tempo possibile all'interno di un'Italia in miniatura."
            }
          ]
        },
        /*{
          items: [
            {
              title: 'What if require further assistance?',
              desc: 'It the readable content of a page when looking at it layout using normal distribution opposed.'
            }
          ]
        },
        {
          items: [
            {
              title: 'What is online banking',
              desc: 'It the readable content of a page when looking at it layout using normal distribution opposed.'
            }
          ]
        },
        {
          items: [
            {
              title: 'What if require further assistance?',
              desc: 'It the readable content of a page when looking at it layout using normal distribution opposed.'
            }
          ]
        },
        {
          items: [
            {
              title: 'What if require further assistance?',
              desc: 'It the readable content of a page when looking at it layout using normal distribution opposed.'
            }
          ]
        },
        {
          items: [
            {
              title: 'What if require further assistance?',
              desc: 'It the readable content of a page when looking at it layout using normal distribution opposed.'
            }
          ]
        },
        {
          items: [
            {
              title: 'Can I track my daily tasks?',
              desc: 'It the readable content of a page when looking at it layout using normal distribution opposed.'
            }
          ]
        }*/
      ],
      currentPage: 1,
      maxPerPage: 2,
      showReadMore: true
    }
  },
  computed: {
    totalResults () {
      return Object.keys(this.orders).length
    },
    pageCount () {
      return Math.ceil(this.totalResults / this.maxPerPage)
    },
    pageOffest () {
      return this.maxPerPage * this.currentPage
    },
    paginatedOrders () {
      return this.orders.slice(0, this.currentPage * this.maxPerPage)
    }
  },
  methods: {
    loadMore () {
      this.currentPage += 1
    }
  }
}
</script>

<style>
</style>
