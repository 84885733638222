<template>
    <!-- Begin Pricing Area -->
    <div id="pGame" class="pricing-area pricing-style-4">
      <div class="container">
        <div class="section-area">
            <h2 class="section-title">Giochi ed Attivit&aacute;</h2>
            <p class="section-desc mb-5">Iscriviti ai giochi e le attivit&aacute; aperte al pubblico durante tutta la giornata</p>
        </div>
        <div class="row">
          <div class="col-12 d-flex justify-content-center">
            <div class="banner-btn-wrap mb-5">
              <router-link class="btn btn-custom-size blackberry-color extbot-primary-hover-4" to="/gameSubscribe">
                <span style="font-size:1.2em"><i class="icofont-pencil-alt-1" ></i> Iscriviti</span>
              </router-link>
              
            </div>
          </div>
          <div class="col-12">
            <div class="pricing-tab-content">
              <div class="pricing-item-wrap row">
                <component :is="showWhich"></component>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Pricing Area End Here -->
</template>
<script>
import MonthlyThree from '@/pricing/MonthlyThree.vue'
import YearlyThree from '@/pricing/YearlyThree.vue'

export default {
  components: {
    MonthlyThree,
    YearlyThree
  },
  data () {
    return {
      showWhich: 'YearlyThree'
    }
  }
}
</script>
