<template>
    <!-- Begin Feature Area -->
    <div id="feature" class="feature-area feature-style-2" style="background-color: #292929 ;">
        <div class="container" >
            <div class="row">
                <div class="col-12">
                    <div class="section-area section-style-3" >
                        <div class="section-wrap" style="padding-bottom: 0px; margin-bottom:30px;">
                            <span class="trial-category" style="margin-bottom:0">Scopri cosa visitare</span>
                            <h2 class="section-title" style="color:white">Espositori</h2>
                            <p class="section-desc" style="color:white">Vieni a conoscere e vedere i nuovi prodotti dei nostri concessionari e non solo
                            </p>
                        </div>
                        
                    </div>
                    <swiper class="swiper-container feature-slider-2" 
                        :breakpoints="swiperOptions.breakpoints"
                        :slides-per-view="2"
                        :spaceBetween="30"
                        :loop="false"
                        :effect='fade'
                        :pagination="swiperOptions.pagination"
                        @swiper="onSwiper"
                    >
                        <swiper-slide  v-for="(slider, sliders) in sliders" :key="sliders" class="feature-item" style="border-radius: 5px;">
                            <div class="col-12">
                              <a :href=slider.url target="_blank"><img :src="`${slider.imgSrc}`" :alt="slider.alt" style="max-height:150px"></a>
                            </div>
                        </swiper-slide>
                    </swiper>
                    <div class="swiper-pagination partner-pagination" :class="swiperStyleClass" style="margin-bottom:30px;"></div>
                </div>
            </div>
        </div>
    </div>
    <!-- Features Area End Here -->
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import SwiperCore, { Pagination } from 'swiper'
SwiperCore.use([Pagination])

export default {
  components: {
    Swiper,
    SwiperSlide
  },

  data () {
    return {
      swiperOptions: {
        breakpoints: {
          320: {
            slidesPerView: 1,
            slidesPerColumn: 1,
            slidesPerGroup: 1,
            slidesPerColumnFill: 'row'
          },
          576: {
            slidesPerView: 1,
            slidesPerColumn: 2,
            slidesPerGroup: 1,
            slidesPerColumnFill: 'row'
          },
          992: {
            slidesPerView: 2,
            slidesPerColumn: 2,
            slidesPerGroup: 1,
            slidesPerColumnFill: 'row'
          }
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          type: 'bullets'
        }
      },
      sliders: [
        {
          imgSrc: 'images/feature/loghi/UTA.png',
          alt: 'UTA',
          url:'https://web.uta.com/it/'
        },
        {
          imgSrc: 'images/feature/loghi/Lotras.png',
          alt: 'Lotras',
          url:'http://www.lotras.it/it/'
        },
        {
          imgSrc: 'images/feature/loghi/tgl.png',
          alt: 'TheGINLabs s.r.l.',
          url:'https://theginlabs.it/'
        },
        {
          imgSrc: 'images/feature/loghi/mp.png',
          alt: 'Concessionaria MP',
          url:'https://www.facebook.com/mpmarravolvotrucks/?locale=it_IT'
        },
        {
          imgSrc: 'images/feature/loghi/ribatti.png',
          alt: 'Ribbatti',
          url:'https://www.ribatti.it/'
        },
        {
          imgSrc: 'images/feature/loghi/pepegomme.png',
          alt: 'Pepe Gomme',
          url:'https://www.pepegomme.it/'
        },
        {
          imgSrc: 'images/feature/loghi/saicar.png',
          alt: 'Saicar',
          url:'https://www.saicarspa.it/'
        },
        {
          imgSrc: 'images/feature/loghi/spurgo.png',
          alt: 'Spurgo Canaljet',
          url:'https://www.spurgocanaljet.com/'
        },
        {
          imgSrc: 'images/feature/loghi/turbosevice.png',
          alt: 'Turbo Service',
          url:'https://turboservice.linde-mh.it/it/'
        },
        {
          imgSrc: 'images/feature/loghi/primiceri.png',
          alt: 'Trasporti F.lli Primiceri s.r.l.',
          url:'https://www.trasportiprimiceri.com/'
        },
        {
          imgSrc: 'images/feature/loghi/derosa.png',
          alt: 'De Rosa Transport',
          url:'https://www.derosaautotrasporti.com/'
        },
        {
          imgSrc: 'images/feature/loghi/carmosino.png',
          alt: 'Carmosino',
          url:'https://carmosinorimorchi.it/'
        },
        {
          imgSrc: 'images/feature/loghi/denuzzo.png',
          alt: 'Denuzzo - Concessionario Renault',
          url:'https://salentauto-denuzzo.concessionaria.renault.it/'
        }, 
        {
          imgSrc: 'images/feature/loghi/fashion.png',
          alt: 'Truck Fashion',
          url:'https://www.truckfashion.it/it/'
        },  
        {
          imgSrc: 'images/feature/loghi/pollicelli.png',
          alt: 'Pollicelli',
          url:'https://pollicelli.it/project-category/allestimenti-speciali/'
        },
        {
          imgSrc: 'images/feature/loghi/rvi.png',
          alt: 'RVI',
          url:'http://www.meccanicarvi.it'
        },  
  

        
        
        
      ]
    }
  },

  methods: {
    onSwiper (swiper) {
      this.swiper = swiper
    }
  }

}

</script>

<style lang="scss">
  .style-3 {
      .swiper-pagination-bullet{
          margin: 0 4px;
      }
  }
  </style>
  
